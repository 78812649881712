import PageTitle from "../shared/PageTitle";
import styles from "./styles/ObservabilitySettings.module.scss";
import { ExpandableSection } from "shared/components/ExpandableSection";
import GrafanaIcon from "icons/grafana.svg";
import NewRelicIcon from "icons/new-relic.svg";
import { axiosFetcher } from "shared/utils/utils";
import useSWR from "swr";
import ClipboardPre from "shared/components/ClipboardPre";
import { useCallback, useState } from "react";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import { Loader } from "shared/components/graph/Loader";
import { OBSERVABILITY_NAV } from "shared/constants/navigation";

const getSectionTitle = (title: string) => {
    return (
        <div className={styles.sectionTitle}>
            {title === "grafana" && (
                <>
                    <GrafanaIcon />
                    <div>Grafana</div>
                </>
            )}
            {title === "newrelic" && (
                <>
                    <NewRelicIcon />
                    <div>New Relic</div>
                </>
            )}
        </div>
    );
};

const GrafanaBlock = ({
    label,
    value,
    type,
}: {
    label: string;
    value: string;
    type?: "default" | "password";
}) => {
    const [tooltipContent, setTooltipContent] = useState(`Copy ${label}`);
    const handleCopyClick = useCallback(() => {
        navigator.clipboard.writeText(value);
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [value]);
    return (
        <div className={styles.grafanaBlock}>
            <div className={styles.label}>{label}</div>
            <ClipboardPre
                tooltip={tooltipContent}
                onCopyClick={handleCopyClick}
            >
                {type === "password" ? "*".repeat(value.length) : value}
            </ClipboardPre>
        </div>
    );
};

const GrafanaDetails = () => {
    const { data, isLoading } = useSWR(
        ["get", "/api/v1/auth/observability_credentials"],
        axiosFetcher
    );

    if (isLoading) {
        return (
            <div className={styles.sectionInfo}>
                <Loader height={200} />
            </div>
        );
    }

    return (
        <>
            <div className={styles.sectionInfo}>
                Configure your Grafana to access Fennel metrics. See docs for{" "}
                <a
                    href="https://fennel.ai/docs/observability/grafana"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span>Instructions</span> <ArrowNarrowUpRightIcon />{" "}
                </a>
            </div>
            {data?.public_prometheus_url ? (
                <div className={styles.grafanaSection}>
                    <GrafanaBlock
                        label="Prometheus URL"
                        value={data.public_prometheus_url}
                    />
                    <GrafanaBlock
                        label="Basic Auth Username"
                        value={data.public_prometheus_auth_username}
                    />
                    <GrafanaBlock
                        label="Basic Auth Password"
                        value={data.public_prometheus_auth_password}
                        type="password"
                    />
                </div>
            ) : (
                <div className={styles.contactSection}>
                    Contact Fennel support to setup your Grafana integration
                </div>
            )}
        </>
    );
};

const NewRelicDetails = () => {
    return (
        <>
            <div className={styles.sectionInfo}>
                Configure your New Relic to access Fennel metrics. See docs for{" "}
                <a
                    href="https://fennel.ai/docs/observability/newrelic"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    <span>Instructions</span> <ArrowNarrowUpRightIcon />{" "}
                </a>
            </div>
            <div className={styles.contactSection}>
                Contact Fennel support to setup your New Relic integration
            </div>
        </>
    );
};

export const ObservabilitySettings = () => {
    return (
        <div className={styles.root}>
            <PageTitle
                title={OBSERVABILITY_NAV.title}
                description="Configure third-party integrations for your organization and extend the functionality of your Console."
                titleIcon={OBSERVABILITY_NAV.icon}
            />
            <div className={styles.section}>
                <ExpandableSection title={getSectionTitle("grafana")}>
                    <GrafanaDetails />
                </ExpandableSection>

                <ExpandableSection title={getSectionTitle("newrelic")}>
                    <NewRelicDetails />
                </ExpandableSection>
            </div>
        </div>
    );
};
