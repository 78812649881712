import { MouseEventHandler, useCallback } from "react";
import axios from "axios";
import classNames from "classnames";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import styles from "./styles/Navbar.module.scss";

import { toast_json_error } from "shared/utils/toast";
import { branchedHome } from "shared/utils/utils";
import { useCurrentUser, useHasPermission } from "../../context/CurrentUser";

import BranchSelector from "./BranchSelector";
import Avatar from "shared/components/Avatar";
import DropdownMenu, {
    DropdownSeparator,
} from "shared/components/DropdownMenu";
import MenuItem from "shared/components/MenuItem";

import Logo from "icons/logo_full.svg";
import SettingsIcon from "icons/settings.svg";
import OrgIcon from "icons/organization.svg";
import LogOutIcon from "icons/logout.svg";
import BranchIcon from "icons/branch.svg";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import {
    GENERAL_SETTINGS_NAV,
    MEMBERS_NAV,
    OBSERVABILITY_NAV,
    ROLES_NAV,
    SERVICE_NAV,
    settingTabsParams,
    SSO_NAV,
    tabParams,
    TOKEN_NAV,
} from "shared/constants/navigation";

function Navbar(): JSX.Element {
    const { user } = useCurrentUser();
    const navigate = useNavigate();
    const { branchName } = useParams();

    const onLogout: MouseEventHandler = useCallback((e) => {
        e.preventDefault();
        axios
            .post("/logout")
            .then(() => {
                navigate("/login");
            })
            .catch((e) => toast_json_error(e, "Failed to log out"));
    }, []);

    const returnToHome = () => {
        if (branchName) {
            navigate(branchedHome(branchName));
        } else {
            window.location.href = "/";
        }
    };
    return (
        <header>
            <div className={styles.gridContainer}>
                <div className={styles.flexContainer}>
                    <nav className={styles.leftNav}>
                        <div className={styles.header}>
                            <Logo
                                width={"5rem"}
                                height={"1.5rem"}
                                onClick={returnToHome}
                            />
                            {!!branchName && (
                                <DropdownMenu
                                    sideOffset={4}
                                    trigger={
                                        <div className={styles.dropdown}>
                                            <Avatar
                                                size="medium"
                                                name={user?.first_name || ""}
                                            />
                                        </div>
                                    }
                                    align={"start"}
                                >
                                    <MenuItem
                                        icon={<BranchIcon />}
                                        onClick={() => navigate("/branches/")}
                                    >
                                        Branches
                                    </MenuItem>
                                    <MenuItem
                                        icon={<SettingsIcon />}
                                        onClick={() => navigate("/settings/")}
                                    >
                                        Settings
                                    </MenuItem>
                                    <MenuItem
                                        icon={<OrgIcon />}
                                        onClick={() => navigate("/usage/")}
                                    >
                                        Usage
                                    </MenuItem>
                                    <DropdownSeparator />
                                    <MenuItem
                                        icon={<LogOutIcon />}
                                        onClick={onLogout}
                                    >
                                        Logout
                                    </MenuItem>
                                </DropdownMenu>
                            )}
                        </div>
                        {branchName && <BranchSelector />}
                        <Tabs />
                    </nav>
                    <nav className={classNames(styles.bottomNav, styles.tab)}>
                        {branchName ? (
                            <>
                                <ArrowNarrowUpRightIcon />
                                <a
                                    href="https://docs.fennel.ai"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Documentation
                                </a>
                            </>
                        ) : (
                            <>
                                <LogOutIcon />
                                <div
                                    className={styles.dropdown}
                                    onClick={onLogout}
                                >
                                    Logout
                                </div>
                            </>
                        )}
                    </nav>
                </div>
            </div>
        </header>
    );
}

function Tabs(): JSX.Element {
    const permission_map = useHasPermission();
    const { branchName } = useParams();
    if (!branchName) {
        // In the settings page, no branchName
        return (
            <>
                <div className={styles.tabs}>
                    <div>
                        <h4>Setup</h4>
                    </div>
                    <div className={styles.tabs}>
                        {settingTabsParams.map(({ title, link, icon }) => (
                            <Tab
                                title={title}
                                key={title}
                                link={link}
                                icon={icon}
                            />
                        ))}
                    </div>
                </div>
                {permission_map?.organization ? (
                    <div className={styles.tabs}>
                        <div>
                            <h4>Organization</h4>
                        </div>
                        <div className={styles.tabs}>
                            {/* <NavItem to="organization">General</NavItem> */}
                            {permission_map.users ? (
                                <Tab {...MEMBERS_NAV} />
                            ) : null}
                            {permission_map.roles ? (
                                <Tab {...ROLES_NAV} />
                            ) : null}
                            {permission_map.serviceAccounts ? (
                                <Tab {...SERVICE_NAV} />
                            ) : null}
                            {permission_map.serviceAccounts ? (
                                <Tab {...SSO_NAV} />
                            ) : null}
                            <Tab {...OBSERVABILITY_NAV} />
                        </div>
                    </div>
                ) : null}
                <div className={styles.tabs}>
                    <div>
                        <h4>Account</h4>
                    </div>
                    <div className={styles.tabs}>
                        <Tab {...GENERAL_SETTINGS_NAV} />
                        <Tab {...TOKEN_NAV} />
                    </div>
                </div>
            </>
        );
    }
    return (
        <div className={styles.tabs}>
            {tabParams.map((tab) =>
                typeof tab === "string" ? (
                    <div className={styles.tabHeader} key={tab}>
                        {tab}
                    </div>
                ) : (
                    <Tab
                        title={tab.title}
                        key={tab.title}
                        link={tab.link(branchName)}
                        icon={tab.icon}
                    />
                )
            )}
        </div>
    );
}

function Tab({
    title,
    link,
    icon,
}: {
    title: string;
    link: string;
    icon?: JSX.Element;
}): JSX.Element {
    return (
        <NavLink to={link}>
            {({ isActive }) => (
                <div
                    className={classNames(
                        styles.tab,
                        isActive && styles.activeTab
                    )}
                >
                    {icon}
                    {title}
                </div>
            )}
        </NavLink>
    );
}

export default Navbar;
