import { useContext, useMemo } from "react";

import { CurrentUserContext } from "./CurrentUserContext";
import { ORG_PERMISSION_GROUPS } from "shared/constants/constants";
import { OrgPermission } from "shared/models";

export const useCurrentUser = () => useContext(CurrentUserContext);

// TODO: Not 100% ideal, also sub-optimal as every time the hook is used the below data is calculated, we could move this up into the main CurrentUser Context, or find a better solution entirely.
export const useHasPermission = () => {
    const { role: { role, rules } = {} } = useCurrentUser();

    return useMemo(() => {
        const isSuperAdmin = role?.name === "Super Admin";
        const organization =
            isSuperAdmin || rules?.some(({ rule: { kind } }) => kind === "org");
        const allOrgPermissions = rules?.some(
            ({ rule: { kind, permissions } }) =>
                kind === "org" &&
                (permissions as OrgPermission[])?.includes(OrgPermission.ALL)
        );

        const ALLOW_ALL = isSuperAdmin || allOrgPermissions;

        return {
            organization,
            default_role:
                ALLOW_ALL ||
                rules?.some(
                    ({ rule }) =>
                        rule.kind === "org" &&
                        ORG_PERMISSION_GROUPS.default_role.every(
                            (p: OrgPermission) => rule.permissions?.includes(p)
                        )
                ),
            roles:
                ALLOW_ALL ||
                rules?.some(
                    ({ rule }) =>
                        rule.kind === "org" &&
                        ORG_PERMISSION_GROUPS.roles.every((p: OrgPermission) =>
                            rule.permissions?.includes(p)
                        )
                ),
            users:
                ALLOW_ALL ||
                rules?.some(
                    ({ rule }) =>
                        rule.kind === "org" &&
                        ORG_PERMISSION_GROUPS.users.every((p: OrgPermission) =>
                            rule.permissions?.includes(p)
                        )
                ),
            tokens:
                ALLOW_ALL ||
                rules?.some(
                    ({ rule }) =>
                        rule.kind === "org" &&
                        ORG_PERMISSION_GROUPS.tokens.every((p: OrgPermission) =>
                            rule.permissions?.includes(p)
                        )
                ),
            serviceAccounts:
                ALLOW_ALL ||
                rules?.some(
                    ({ rule }) =>
                        rule.kind === "org" &&
                        ORG_PERMISSION_GROUPS.service_accounts.every(
                            (p: OrgPermission) => rule.permissions?.includes(p)
                        )
                ),
        };
    }, [role, rules]);
};
