import PageTitle from "../shared/PageTitle";
import styles from "./SSOSettings.module.scss";
import { ExpandableSection } from "shared/components/ExpandableSection";
import { axiosFetcher } from "shared/utils/utils";
import useSWR from "swr";
import ClipboardPre from "shared/components/ClipboardPre";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Field, TextInput } from "shared/components/TextInput";
import Button from "shared/components/Button";
import axios from "axios";
import { SSO_NAV } from "shared/constants/navigation";
import { toast_error, toast_success } from "shared/utils/toast";
import Switch from "shared/components/Switch";
import ReloadIcon from "icons/reload.svg";

const getSectionTitle = (title: string) => {
    return (
        <div className={styles.sectionTitle}>
            {title === "google" && (
                <>
                    <div>Google</div>
                </>
            )}
            {title === "saml" && (
                <>
                    <div>SAML</div>
                </>
            )}
        </div>
    );
};

const CopyBlock = ({
    label,
    value,
    type,
}: {
    label: string;
    value: string;
    type?: "default" | "password";
}) => {
    const [tooltipContent, setTooltipContent] = useState(`Copy ${label}`);
    const handleCopyClick = useCallback(() => {
        navigator.clipboard.writeText(value);
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [value]);
    return (
        <div className={styles.samlBlock}>
            <div className={styles.label}>{label}</div>
            <ClipboardPre
                tooltip={tooltipContent}
                onCopyClick={handleCopyClick}
            >
                {type === "password" ? "*".repeat(value.length) : value}
            </ClipboardPre>
        </div>
    );
};

const SCIMDetails = ({ enabled }: { enabled: boolean }) => {
    const { data } = useSWR(["get", "/saml/token"], axiosFetcher);
    const [isSyncing, setIsSyncing] = useState(false);
    const onSyncCallback = async () => {
        try {
            setIsSyncing(true);
            await axios.post("/users/sync");
            toast_success("Sync Complete");
        } catch (e) {
            toast_error("Something went wrong");
        } finally {
            setIsSyncing(false);
        }
    };

    return (
        <>
            <div className={styles.sectionInfo}>
                Locate the SCIM Provisioning URL and activate HTTP
                Authentication in your IdP using the provided token.
            </div>
            <div className={styles.samlSection}>
                <div className={styles.samlBlock}>
                    <CopyBlock
                        label="SCIM endpoint"
                        value={data?.scim_url || ""}
                    />
                </div>
                <div className={styles.samlBlock}>
                    <CopyBlock
                        label="Authentication Token"
                        value={data?.token || ""}
                        type="password"
                    />
                </div>
                {enabled && (
                    <div className={styles.toggleContainer}>
                        <Button
                            icon={<ReloadIcon />}
                            color="neutral"
                            variant="outline"
                            onClick={onSyncCallback}
                        >
                            {isSyncing ? "Syncing ..." : "Sync From IdP"}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

const SAMLDetails = ({
    data,
}: {
    data: {
        sso_url: string;
        x509_cert: string;
        entity_id: string;
        scim: boolean;
    };
}) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
    } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (data) {
            reset({
                ...data,
            });
        }
    }, [data]);

    const onSubmit = useCallback(async (values: any) => {
        try {
            setIsSubmitting(true);
            await axios.post("/team/saml", {
                ...values,
                scim: values.scim === undefined ? false : values.scim,
            });
            toast_success("Saved successfully");
        } catch (e) {
            toast_error("Something went wrong");
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
    }, []);
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.infoForm}>
            <div className={styles.sectionInfo}>
                Add support for a custom SAML provider (e.g. Okta, Google
                Workspace, etc.)
            </div>
            <div className={styles.samlSection}>
                <CopyBlock
                    label="Fennel ACS URL"
                    value={
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/saml/acs"
                    }
                />
            </div>
            <div className={styles.samlSection}>
                <Controller
                    control={control}
                    name={"sso_url"}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Field
                            label={"Provider Sign On URL"}
                            className={styles.samlBlock}
                        >
                            <div>
                                <TextInput {...field} />
                                <div className={styles.sectionInfo}>
                                    This will be given to you by your ID
                                    provider
                                </div>
                            </div>
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name={"entity_id"}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Field
                            label={"Provider Issuer/Entity ID"}
                            className={styles.samlBlock}
                        >
                            <div>
                                <TextInput {...field} />
                                <div className={styles.sectionInfo}>
                                    This will be given to you by your ID
                                    provider
                                </div>
                            </div>
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name={"x509_cert"}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Field
                            label={"x.509 PEM Certificate"}
                            className={styles.samlBlock}
                        >
                            <div>
                                <TextInput
                                    type="textarea"
                                    className={styles.certInput}
                                    {...field}
                                />
                                <div className={styles.sectionInfo}>
                                    This will be given to you by your ID
                                    provider
                                </div>
                            </div>
                        </Field>
                    )}
                />
            </div>
            <Controller
                control={control}
                name="scim"
                render={({ field }) => {
                    return (
                        <div className={styles.scimSection}>
                            <div className={styles.toggleContainer}>
                                <span>Enable SCIM</span>
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    name={"SCIM"}
                                    onCheckedChange={field.onChange}
                                />
                            </div>
                            {field.value && (
                                <>
                                    <SCIMDetails
                                        enabled={data?.scim || false}
                                    />
                                </>
                            )}
                        </div>
                    );
                }}
            />
            <div>
                <Button
                    size="small"
                    color="primary"
                    disabled={!isDirty || !isValid}
                    type="submit"
                    loading={isSubmitting}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

const GoogleDetails = () => {
    return (
        <>
            <div className={styles.sectionInfo}>
                Enable login through Google for your organization. This will
                delete your other saved preferences for login.
            </div>
            <div>
                <Button
                    size="small"
                    color="primary"
                    type="submit"
                    onClick={async () => {
                        try {
                            await axios.delete("/team/saml");
                            toast_success("Saved successfully");
                        } catch (e) {
                            toast_error(
                                "There was a problem in saving your settings"
                            );
                        }
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
};

export const SSOSettings = () => {
    const { data } = useSWR(["get", "/team/saml"], axiosFetcher);
    return (
        <div className={styles.root}>
            <PageTitle
                title={SSO_NAV.title}
                description="Configure Single Sign-On (SSO) for your organization and simplify user access across your Console."
                titleIcon={SSO_NAV.icon}
            />
            <div className={styles.section}>
                <ExpandableSection
                    title={getSectionTitle("saml")}
                    expanded={!!data}
                    isToggle
                >
                    <SAMLDetails data={data} />
                </ExpandableSection>

                <ExpandableSection
                    title={getSectionTitle("google")}
                    expanded={!data}
                    isToggle
                >
                    <GoogleDetails />
                </ExpandableSection>
            </div>
        </div>
    );
};
